import config from "../config.json";

export default class OptimizedProfilesCalculator {
    constructor() {

        this.cuttingMargin = 5;
        this.allowedUndercut = 7;

        this.walls = [];
        this.wallsWithRestartProfile = [];
        this.wallResults = [];
        this.neededProfiles = [];
        this.noSolution = false;
        this.changedStart = false;
        this.wallOvershoot = [];
    }

    /**
     * check if solution possible
     **/
    getNoSolution() {
        return this.noSolution;
    }

    /**
     * check if start profile changed
     **/
    getChangedStart() {
        return this.changedStart;
    }

    /**
     * Get all profiles for each wall
     **/
    getWallResults() {
        return this.wallResults;
    }

    /**
     * Get all overshoots
     **/

    getWallOvershoot() {
        return this.wallOvershoot;
    }

    /**
     * Calculate profiles needed
     * 
     * @param {*} walls 
     * @param {*} units 
     * @returns 
     */
    calculateProfiles = async (walls, units) => {

        //get profiles from backend
        let profilesApi = await fetch(`${config.api}/profiles`).then(response => response.json());
        let newProfiles = {};
        for (let profile of profilesApi) {
            newProfiles[profile.reference] = profile;
        };
        this.profiles = newProfiles;

        //get packs from backend
        let packsApi = await fetch(`${config.api}/profile-packs`).then(response => response.json());
        let newPacks = {};
        for (let pack of packsApi) {
            newPacks[pack.reference] = pack;
        };
        this.profilePacks = newPacks;

        // units have to be mm
        if (units === 'cm') {
            walls.forEach(wall => {
                wall.width = wall.width * 10;
                wall.height = wall.height * 10;
            });
        }

        // set restart profile
        walls.forEach((wall, index) => {
            if (wall.restartProfile && (!wall.isTouching || index === 0)) this.wallsWithRestartProfile.push(index);
        });

        this.walls = walls;

        //loop trough walls and pieces needed
        walls.forEach((wall, wallIndex) => {
            this.calculateRegularProfiles(wallIndex);
            this.calculateNeededSpecialPieces(wallIndex);
        });

        //optimize cutting
        let optimizedForCutting = this.optimizeCutting();

        //optimize packing
        return this.optimizePacking(optimizedForCutting);
    }

    /**
     * Calculate the needed special pieces, like start, end, inner and outer corners
     * 
     * @param {*} wallIndex 
     */
    calculateNeededSpecialPieces(wallIndex) {
        let wall = this.walls[wallIndex];
        let nextWall = typeof this.walls[wallIndex + 1] === 'undefined' ? null : this.walls[wallIndex + 1];
        let previousWall = wallIndex !== 0 ? this.walls[wallIndex - 1] : null;

        if (typeof this.wallResults[wallIndex] === 'undefined') { 
            this.wallResults[wallIndex] = [];
        }

        // Add start profile
        if ((wallIndex === 0) || (wall.isTouching === false)) {
            if (this.wallsWithRestartProfile.includes(wallIndex)) {
                this.wallResults[wallIndex].push({
                    profile: this.profiles.restartProfile,
                    height: wall.height
                });
                this.neededProfiles.push({
                    profile: this.profiles.restartProfile,
                    height: wall.height
                });
            } else {
                this.wallResults[wallIndex].push({
                    profile: this.profiles.startProfile,
                    height: wall.height
                });
                this.neededProfiles.push({
                    profile: this.profiles.startProfile,
                    height: wall.height
                });
            }
        }

        // All walls need endprofile
        this.wallResults[wallIndex].push({
            profile: this.profiles.endProfile,
            height: wall.height
        });
        this.neededProfiles.push({
            profile: this.profiles.endProfile,
            height: wall.height
        });

        // Add baseprofile if true end
        if ((wallIndex === (this.walls.length - 1)) || (nextWall?.isTouching === false)) {
            this.wallResults[wallIndex].push({
                profile: this.profiles.baseProfile,
                height: wall.height
            });
            this.neededProfiles.push({
                profile: this.profiles.baseProfile,
                height: wall.height
            });
        }

        // Add outer corner
        if (wall.angle === 'outside') {
            this.wallResults[wallIndex].push({
                profile: this.profiles.outerCornerProfile,
                height: wall.height < previousWall.height ? wall.height : previousWall.height
            });
            this.neededProfiles.push({
                profile: this.profiles.outerCornerProfile,
                height: wall.height < previousWall.height ? wall.height : previousWall.height
            });
            /*this.wallResults[wallIndex].push({
                profile: this.profiles.endProfile,
                height: wall.height < previousWall.height ? wall.height : previousWall.height,
                partOfCorner: true
            });
            this.neededProfiles.push({
                profile: this.profiles.endProfile,
                height: wall.height < previousWall.height ? wall.height : previousWall.height,
                partOfCorner: true
            });*/
            if (wall.height !== previousWall.height) {
                if (wall.height > previousWall.height) {
                    this.wallResults[wallIndex].push({
                        profile: this.profiles.startProfile,
                        height: wall.height - previousWall.height
                    });
                    this.neededProfiles.push({
                        profile: this.profiles.startProfile,
                        height: wall.height - previousWall.height
                    });
                } else {
                    this.wallResults[wallIndex].push({
                        profile: this.profiles.baseProfile,
                        height: previousWall.height - wall.height
                    });
                    this.neededProfiles.push({
                        profile: this.profiles.baseProfile,
                        height: previousWall.height - wall.height
                    });
                }
            }
        }

        // Add inner corner
        if (wall.angle === 'inside') {
            this.wallResults[wallIndex].push({
                profile: this.profiles.insideCornerProfile,
                height: wall.height < previousWall.height ? wall.height : previousWall.height
            });
            this.neededProfiles.push({
                profile: this.profiles.insideCornerProfile,
                height: wall.height < previousWall.height ? wall.height : previousWall.height
            });
            /*this.wallResults[wallIndex].push({
                profile: this.profiles.endProfile,
                height: wall.height < previousWall.height ? wall.height : previousWall.height,
                partOfCorner: true
            });
            this.neededProfiles.push({
                profile: this.profiles.endProfile,
                height: wall.height < previousWall.height ? wall.height : previousWall.height,
                partOfCorner: true
            });*/
            if (wall.height !== previousWall.height) {
                if (wall.height > previousWall.height) {
                    this.wallResults[wallIndex].push({
                        profile: this.profiles.startProfile,
                        height: wall.height - previousWall.height
                    });
                    this.neededProfiles.push({
                        profile: this.profiles.startProfile,
                        height: wall.height - previousWall.height
                    });
                } else {
                    this.wallResults[wallIndex].push({
                        profile: this.profiles.baseProfile,
                        height: previousWall.height - wall.height
                    });
                    this.neededProfiles.push({
                        profile: this.profiles.baseProfile,
                        height: previousWall.height - wall.height
                    });
                }
            }
        }
    }

    /**
     * Calculate the amount of regular profiles needed, only width
     * 
     * @param {*} wallIndex 
     */
    calculateRegularProfiles(wallIndex) {
        let wall = this.walls[wallIndex];
        let nextWall = typeof this.walls[wallIndex + 1] === 'undefined' ? null : this.walls[wallIndex + 1];
        let previousWall = wallIndex !== 0 ? this.walls[wallIndex - 1] : null;

        let wallToCover = wall.width;
        let shorter = 0;

        // if start
        if ((wallIndex === 0) || (wall.isTouching === false)) {
            if (this.wallsWithRestartProfile.includes(wallIndex)) {
                wallToCover -= this.profiles.restartProfile.width;
                shorter += this.profiles.restartProfile.shorter;
            } else {
                wallToCover -= this.profiles.startProfile.width;
                shorter += this.profiles.startProfile.shorter;
            }
        }

        // if end
        wallToCover -= this.profiles.endProfile.width;
        shorter += this.profiles.endProfile.shorter;
        if ((wallIndex === (this.walls.length - 1)) || (nextWall?.isTouching === false)) {
            wallToCover -= this.profiles.baseProfile.width;
            shorter += this.profiles.baseProfile.shorter;
        }

        //if outer corner
        if (wall.angle === 'outside') {
            wallToCover -= this.profiles.outerCornerProfile.width;
            shorter += this.profiles.outerCornerProfile.shorter;
        }

        //if inner corner
        if (wall.angle === 'inside') {
            wallToCover -= this.profiles.insideCornerProfile.width;
            shorter += this.profiles.insideCornerProfile.shorter;
        }

        //fill with 1 line profiles
        let result1 = this.fillOneLinesProfiles(wall, wallToCover, shorter);
        //fill with 2 lines profiles
        let result2 = this.fillTwoLinesProfiles(wall, wallToCover, shorter);

        console.log('result1');
        console.log(result1);
        console.log('result2');
        console.log(result2);

        //decide on best result
        let decision = this.decideOnBestResult(result1, result2);
        let result = decision.result;
        this.noSolution = decision.noSolution;

        //if no solution, try with restart profile
        if (decision.noSolution && ((wallIndex === 0) || (wall.isTouching === false))) {

            //set new values
            let newWallToCover = wallToCover + this.profiles.startProfile.width - this.profiles.restartProfile.width;
            let newShorter = shorter - this.profiles.startProfile.shorter + this.profiles.restartProfile.shorter;
            if (this.wallsWithRestartProfile.includes(wallIndex)) {
                newWallToCover = wallToCover + this.profiles.restartProfile.width - this.profiles.startProfile.width;
                newShorter = shorter - this.profiles.restartProfile.shorter + this.profiles.startProfile.shorter;
            }

            //fill with 1 line profiles
            let result1 = this.fillOneLinesProfiles(wall, newWallToCover, newShorter);
            console.log('result3');
            console.log(result1);
            //fill with 2 lines profiles
            let result2 = this.fillTwoLinesProfiles(wall, newWallToCover, newShorter);
            console.log('result4');
            console.log(result2);

            //decide on best result
            let newDecision = this.decideOnBestResult(result1, result2);

            //check if fixed
            if (!newDecision.noSolution) {
                result = newDecision.result;

                //add/remove restart profile
                if (this.wallsWithRestartProfile.includes(wallIndex)) {
                    const wwrpIndex = this.wallsWithRestartProfile.indexOf(wallIndex);
                    if (wwrpIndex > -1) { // only splice array when item is found
                        this.wallsWithRestartProfile.splice(wwrpIndex, 1); // 2nd parameter means remove one item only
                    }
                } else {
                    this.wallsWithRestartProfile.push(wallIndex);
                }

                //set changed start
                this.changedStart = true;
            }

            //set overshoot
            this.wallOvershoot[wallIndex] = result.overshoot;

            //set no solution
            this.noSolution = newDecision.noSolution;

        }

        //add to needed profiles
        for (let i = 0; i < result.twoLinesProfilesCount; i++) {

            if (typeof this.wallResults[wallIndex] === 'undefined') {
                this.wallResults[wallIndex] = [];
            }

            this.wallResults[wallIndex].push({
                profile: this.profiles.twoLinesProfileHigh,
                height: wall.height
            });

            this.neededProfiles.push({
                profile: this.profiles.twoLinesProfileHigh,
                height: wall.height
            });
        }

        for (let i = 0; i < result.oneLinesProfilesCount; i++) {

            if (typeof this.wallResults[wallIndex] === 'undefined') {
                this.wallResults[wallIndex] = [];
            }

            this.wallResults[wallIndex].push({
                profile: this.profiles.oneLinesProfile,
                height: wall.height
            });

            this.neededProfiles.push({
                profile: this.profiles.oneLinesProfile,
                height: wall.height
            });
        }
    }

    /**
     * 
     * Decide witch is best result
     * 
     * @param {*} result1 
     * @param {*} result2 
     * @returns 
     */
    decideOnBestResult(result1, result2) {

        let result;
        let noSolution = false;

        //make decision on best result
        if (
            (result1.overshoot - result1.shorter) <= 0 &&
            (result2.overshoot - result2.shorter) > 0
        ) {
            result = result1;
        } else if (
            (result2.overshoot - result2.shorter) <= 0 &&
            (result1.overshoot - result1.shorter) > 0
        ) {
            result = result2;
        } else if (
            (result1.overshoot - result1.shorter) <= 0 &&
            (result2.overshoot - result2.shorter) <= 0
        ) {
            result = result1.twoLinesProfilesCount > result2.twoLinesProfilesCount ? result1 : result2;
        } else {
            result = result1.twoLinesProfilesCount > result2.twoLinesProfilesCount ? result1 : result2;
            noSolution = true;
        }

        //return
        return {
            result: result,
            noSolution: noSolution
        }
    }

    /**
     * Fill based on 2 lines profiles
     * 
     * @param {*} wall 
     * @param {*} wallToCover 
     * @param {*} shorterIn 
     * @returns 
     */
    fillTwoLinesProfiles(wall, wallToCover, shorterIn) {
        let shorter = JSON.parse(JSON.stringify(shorterIn));
        let wallLeft = JSON.parse(JSON.stringify(wallToCover));
        let neededProfilesForWall = [];
        //calculate needed profiles
        while (wallLeft > this.allowedUndercut) {
            if (
                ((wallLeft - this.allowedUndercut) > this.profiles.oneLinesProfile.width) ||
                ((wallLeft + shorter) > (this.profiles.twoLinesProfileHigh.width - this.profiles.twoLinesProfileHigh.shorter))
            ) {
                wallLeft -= this.profiles.twoLinesProfileHigh.width;
                shorter += this.profiles.twoLinesProfileHigh.shorter;
                neededProfilesForWall.push({
                    profile: this.profiles.twoLinesProfileHigh,
                    height: wall.height
                });
            } else {
                wallLeft -= this.profiles.oneLinesProfile.width;
                shorter += this.profiles.oneLinesProfile.shorter;
                neededProfilesForWall.push({
                    profile: this.profiles.oneLinesProfile,
                    height: wall.height
                });
            }
        }

        //check if width
        let overshoot = (wallLeft * -1);
        let twoLinesProfiles = neededProfilesForWall.filter(profile => profile.profile.profileCode === this.profiles.twoLinesProfileHigh.profileCode);
        let twoLinesProfilesCount = twoLinesProfiles.length;
        while ((overshoot - shorter) > 0) {
            if (twoLinesProfilesCount > 0) {
                //add 2x 1 line profile
                for (let i = 0; i < 2; i++) {
                    overshoot += this.profiles.oneLinesProfile.width;
                    shorter += this.profiles.oneLinesProfile.shorter;
                    neededProfilesForWall.push({
                        profile: this.profiles.oneLinesProfile,
                        height: wall.height
                    });
                }

                //remove 1x 2 line profile
                overshoot -= this.profiles.twoLinesProfileHigh.width;
                shorter -= this.profiles.twoLinesProfileHigh.shorter;
                twoLinesProfilesCount--;
            } else {
                break;
            }
        }

        //count one lines profiles
        let oneLinesProfiles = neededProfilesForWall.filter(profile => profile.profile.profileCode === this.profiles.oneLinesProfile.profileCode);
        let oneLinesProfilesCount = oneLinesProfiles.length;

        //return 
        return {
            twoLinesProfilesCount: twoLinesProfilesCount,
            oneLinesProfilesCount: oneLinesProfilesCount,
            overshoot: overshoot,
            shorter: shorter
        };
    }

    /**
     * Fill based on 1 lines profiles
     * 
     * @param {*} wall 
     * @param {*} wallToCover 
     * @param {*} shorterIn 
     * @returns 
     */
    fillOneLinesProfiles(wall, wallToCover, shorterIn) {
        let shorter = JSON.parse(JSON.stringify(shorterIn));
        let wallLeft = JSON.parse(JSON.stringify(wallToCover));
        let neededProfilesForWall = [];

        //calculate needed profiles
        while (wallLeft > this.allowedUndercut) {
            wallLeft -= this.profiles.oneLinesProfile.width;
            shorter += this.profiles.oneLinesProfile.shorter;
            neededProfilesForWall.push({
                profile: this.profiles.oneLinesProfile,
                height: wall.height
            });
        }

        //check if width
        let overshoot = (wallLeft * -1);
        let oneLinesProfilesCount = JSON.parse(JSON.stringify(neededProfilesForWall.length));
        let twoLinesProfilesCount = 0;

        //check if changes can be made
        while ((overshoot - shorter) < 0) {
            if (oneLinesProfilesCount < 2) break;

            //replace 2x 1 line profile with 1x 2 line profile
            let newOvershoot = JSON.parse(JSON.stringify(overshoot));
            let newShorter = JSON.parse(JSON.stringify(shorter));
            newOvershoot += this.profiles.twoLinesProfileHigh.width;
            newShorter += this.profiles.twoLinesProfileHigh.shorter;

            for (let i = 0; i < 2; i++) {
                newOvershoot -= this.profiles.oneLinesProfile.width;
                newShorter -= this.profiles.oneLinesProfile.shorter;
            }

            //if still OK, set new values
            if (newOvershoot >= (-1 * this.allowedUndercut) && (newOvershoot - newShorter) <= 0) {
                oneLinesProfilesCount -= 2;
                twoLinesProfilesCount++;
                overshoot = newOvershoot;
                shorter = newShorter;
            } else {
                break;
            }
        }

        //return
        return {
            twoLinesProfilesCount: twoLinesProfilesCount,
            oneLinesProfilesCount: oneLinesProfilesCount,
            overshoot: overshoot,
            shorter: shorter
        };
    }



    /**
     * Optimize cutting, waste as little as possible
     * 
     * @returns 
     */
    optimizeCutting() {

        //get unqiue types
        let uniqueProfiles = [];
        this.neededProfiles.forEach(profile => {
            let found = false;
            uniqueProfiles.forEach(uniqueProfile => {
                if (
                    (uniqueProfile.height === profile.height) &&
                    (uniqueProfile.profile.profileCode === profile.profile.profileCode)
                ) {
                    found = true;
                    uniqueProfile.amount++;
                }
            });
            if (!found) {
                uniqueProfiles.push({
                    profile: profile.profile,
                    height: profile.height,
                    amount: 1
                });
            }
        });

        //sort on size
        uniqueProfiles.sort((a, b) => {
            return b.profile.height - a.profile.height;
        });

        let sortedProfileTypes = {};
        //sort on profiletype
        uniqueProfiles.forEach(profile => {
            if (typeof sortedProfileTypes[profile.profile.profileCode] === 'undefined') {
                sortedProfileTypes[profile.profile.profileCode] = [];
            }
            sortedProfileTypes[profile.profile.profileCode].push(profile);
        });


        //let cut profiles
        let cutProfiles = {};

        //loop trough profiletypes
        for (let [index, profileArray] of Object.entries(sortedProfileTypes)) {

            //add to cutprofiles
            if (typeof cutProfiles[index] === 'undefined') {
                cutProfiles[index] = [];
            }

            //loop all pieces of same profile with different lengths
            for (let profile of profileArray) {

                let profileObj = profile.profile;
                let stdHeight = profileObj.height;

                //loop for all amounts
                for (let i = 0; i < profile.amount; i++) {
                    let added = false;

                    //loop trough all cutprofiles, too see if new bit can be added on
                    for (let fullProfileIndex = 0; fullProfileIndex < cutProfiles[index].length; fullProfileIndex++) {
                        let currentFullProfile = cutProfiles[index][fullProfileIndex];

                        //calculate how much space is currently used
                        let currentFullProfileUsed = this.cuttingMargin * currentFullProfile.length;
                        for (let currentFullProfileLength of currentFullProfile) currentFullProfileUsed += currentFullProfileLength;

                        if ((currentFullProfileUsed + profile.height) <= stdHeight) {
                            cutProfiles[index][fullProfileIndex].push(profile.height);
                            added = true;
                            break;
                        }
                    }
                    //add new full size beam if does not fit
                    if (!added) {
                        cutProfiles[index].push([profile.height]);
                    }
                }

            }
        }

        return cutProfiles;
    }

    /**
     * Get optimized packing
     * 
     * @param {*} cutProfiles 
     * @returns 
     */
    optimizePacking(cutProfiles) {

        //log
        console.log(cutProfiles);

        //1 lines left
        let oneLinesLeft = 0;

        //result
        let resultPacks = [];

        //loop over all cutprofiles (except 2 lines)
        for (let [profileCode, myProfiles] of Object.entries(cutProfiles)) {

            //inner corners needed
            if (profileCode === this.profiles.insideCornerProfile.profileCode) {
                let count = myProfiles.length;
                for (let i = 0; i < count; i++) {
                    resultPacks.push(this.profilePacks.innerCornerPack);
                }
            }

            //outer corners needed
            if (profileCode === this.profiles.outerCornerProfile.profileCode) {
                let count = myProfiles.length;
                for (let i = 0; i < count; i++) {
                    resultPacks.push(this.profilePacks.outerCornerPack);
                }
            }

            //start/stop combo needed
            if (profileCode === this.profiles.startProfile.profileCode) {
                let count = myProfiles.length;
                for (let i = 0; i < count; i++) {
                    resultPacks.push(this.profilePacks.startStopComboPack);
                }
            }

            //restart profile needed
            if (profileCode === this.profiles.restartProfile.profileCode) {
                let count = myProfiles.length;
                for (let i = 0; i < count; i++) {
                    resultPacks.push(this.profilePacks.startStopComboPack);
                    resultPacks.push(this.profilePacks.restartPack);
                }
            }

            //1 line profiles needed
            if (profileCode === this.profiles.oneLinesProfile.profileCode) {

                //get line
                let line = this.profilePacks.oneLinesPack.profilePackLines[0];

                let count = myProfiles.length;
                let packCount = Math.ceil(count / line.amount);
                for (let i = 0; i < packCount; i++) {
                    resultPacks.push(this.profilePacks.oneLinesPack);
                }

                //log how many are left
                oneLinesLeft = (packCount * line.amount) - count;
            }
        }

        //do 2-lines last
        for (let [profileCode, myProfiles] of Object.entries(cutProfiles)) {
            //2 line profiles needed
            if (profileCode === this.profiles.twoLinesProfileHigh.profileCode) {
                let count = myProfiles.length;

                //redact double one profiles that can be used for 2 line profiles
                let doubleOneLines = Math.floor(oneLinesLeft / 2);
                count -= doubleOneLines;

                //how many of those profiles can use the low pack
                let lowPackPotential = 0;
                for (let profile of myProfiles) {
                    let profileLenghtUsed = this.cuttingMargin * (profile.length - 1);
                    for (let profileLength of profile) profileLenghtUsed += profileLength;

                    if (profileLenghtUsed <= this.profiles.twoLinesProfileLow.height) {
                        lowPackPotential++;
                    }
                }

                let highPackRequired = count - lowPackPotential;

                //get line
                let lineHigh = this.profilePacks.twoLinesPackHigh.profilePackLines[0];

                //add high packs
                let highPackCount = Math.ceil(highPackRequired / lineHigh.amount);
                for (let i = 0; i < highPackCount; i++) {
                    resultPacks.push(this.profilePacks.twoLinesPackHigh);
                }

                //add low packs
                let lowPackProfileCount = count - (highPackCount * lineHigh.amount);
                if (lowPackProfileCount > 0) {
                    //get line
                    let lineLow = this.profilePacks.twoLinesPackLow.profilePackLines[0];
                    let lowPackCount = Math.ceil(lowPackProfileCount / lineLow.amount);
                    for (let i = 0; i < lowPackCount; i++) {
                        resultPacks.push(this.profilePacks.twoLinesPackLow);
                    }
                }
            }
        }

        //fix if not enough 2 lines profiles
        let twoLinesProfilesHighCount = resultPacks.filter(pack => pack.reference === this.profilePacks.twoLinesPackHigh.reference).length * this.profilePacks.twoLinesPackHigh.profilePackLines[0].amount;
        let twoLinesProfilesLowCount = resultPacks.filter(pack => pack.reference === this.profilePacks.twoLinesPackLow.reference).length * this.profilePacks.twoLinesPackLow.profilePackLines[0].amount;
        let twoLinesProfilesTotal = twoLinesProfilesHighCount + twoLinesProfilesLowCount;

        //count how many 2 lines profiles are needed
        let twoLinesProfilesNeeded = 0;
        for (let [profileCode, myProfiles] of Object.entries(cutProfiles)) {
            if (profileCode === this.profiles.twoLinesProfileHigh.profileCode) {
                twoLinesProfilesNeeded += myProfiles.length;
            }
            if (profileCode === this.profiles.twoLinesProfileLow.profileCode) {
                twoLinesProfilesNeeded += myProfiles.length;
            }
        }

        //replace some 2lines with one lines, if needed
        for (let i = 0; i < (twoLinesProfilesNeeded - twoLinesProfilesTotal); i++) {
            next:
            for (let [index, wallResult] of Object.entries(this.wallResults)) {
                for (let [index2, profile] of Object.entries(wallResult)) {
                    if (profile.profile.profileCode === this.profiles.twoLinesProfileHigh.profileCode || profile.profile.profileCode === this.profiles.twoLinesProfileLow.profileCode) {
                        this.wallResults[index].push({
                            profile: this.profiles.oneLinesProfile,
                            height: profile.height
                        });
                        this.wallResults[index].push({
                            profile: this.profiles.oneLinesProfile,
                            height: profile.height
                        });
                        this.wallResults[index].splice(index2, 1);
                        break next;
                    }
                }
            }
        }

        //return result
        return resultPacks;
    }
}